
import React, { useState } from 'react';

const FileUpload = () => {
    const [file, setFile] = useState(null);
    const [fileUrl, setFileUrl] = useState('');
    const [uploading, setUploading] = useState(false);

    const API_URL = 'https://api.backblazeb2.com/b2api/v2/b2_authorize_account';
    const APPLICATION_KEY_ID = '005e59c030ccc7f0000000001';
    const APPLICATION_KEY = 'K005NKyF2oGKgJv0OJtcHWbuQrXk0ns';
    const BUCKET_ID = '1ee5295c1093b02c9c1c071f';

    const getAuthAndUploadUrl = async () => {
        try {
            const authResponse = await fetch(API_URL, {
                method: 'GET',
                headers: {
                    'Authorization': 'Basic ' + btoa(APPLICATION_KEY_ID + ':' + APPLICATION_KEY),
                },
            });
            const authData = await authResponse.json();
            const uploadUrlResponse = await fetch(`${authData.apiUrl}/b2api/v2/b2_get_upload_url`, {
                method: 'POST',
                headers: {
                    'Authorization': authData.authorizationToken,
                },
                body: JSON.stringify({ bucketId: BUCKET_ID }),
            });
            const uploadData = await uploadUrlResponse.json();
            return uploadData;
        } catch (error) {
            console.error('Error getting upload URL:', error);
            throw error;
        }
    };

    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setUploading(true);
            const fileContent = await selectedFile.arrayBuffer();

            try {
                const { uploadUrl, authorizationToken } = await getAuthAndUploadUrl();
                const formData = new FormData();
                formData.append('file', selectedFile);

                const hashBuffer = await crypto.subtle.digest('SHA-1', fileContent);
                const hashArray = Array.from(new Uint8Array(hashBuffer));
                const hashHex = hashArray
                    .map((b) => b.toString(16).padStart(2, "0"))
                    .join("");

                const filename = hashHex + selectedFile.name.split('.').pop()

                const uploadResponse = await fetch(uploadUrl, {
                    method: "POST",
                    mode: "cors",
                    body: fileContent,
                    headers: {
                        "Content-Type": "b2/x-auto",
                        "Authorization": authorizationToken,
                        "X-Bz-File-Name": filename,
                        "X-Bz-Content-Sha1": hashHex,
                    },
                });

                if (uploadResponse.ok) {
                    const uploadResult = await uploadResponse.json();
                    const fileUrl = `https://bfile.tech.nyc.mn/${filename}`;
                    setFileUrl(fileUrl);
                } else {
                    console.error('Error uploading file:', uploadResponse.statusText);
                }
            } catch (error) {
                console.error('Error during file upload:', error);
            } finally {
                setUploading(false);
            }
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen p-4">
            <input
                type="file"
                onChange={handleFileChange}
                className="mb-4"
            />
            {uploading && <p className="text-lg text-blue-600">Uploading...</p>}
            {fileUrl && (
                <div>
                    <p className="text-lg text-blue-600">File uploaded successfully!</p>
                    <a href={fileUrl} className="text-blue-500" target="_blank" rel="noopener noreferrer">
                        {fileUrl}
                    </a>
                </div>
            )}
        </div>
    );
};

export default FileUpload;
